<template>
  <div class="container infoMsg">

    <v-header></v-header>

    <div class="scroll-container">
      <div class="header">
        <div class="box">
          <div class="avatar_box">
            <avatar :src="userInfo.photoResourceUrl"></avatar>
          </div>
          <span class="user_name">{{userInfo.username}}</span>
        </div>
      </div>

      <div class="gray"></div>

      <div class="form">

        <!--手机号-->
        <template v-if="userInfo.mobile">
          <div class="form_item">
            <span class="label">手机号码</span>
            <span class="center_txt" v-if="!edit[0]">{{hidePhone(userInfo.mobile)}}</span>
            <span class="center_txt" v-else></span>
            <div class="btn" style="margin-right: 4px" v-if="edit[0]" @click="edit=[false,false,false,false]">取消</div>
            <div :class="['btn', edit[0] && 'sure']" @click="edit=[true,false,false,false]">{{edit[0] ? '确认变更' :
              '变更'}}
            </div>
          </div>
          <div class="edit_item" v-if="edit[0]">
            <div class="tel_input">
              <div class="jia86">
                <div class="select-box">
                  <div class="select-current">
                    <span class="current-name">{{option[index].name}}</span>
                  </div>
                  <div class="option-list" v-if="isShow">
                                <span class="option"
                                      @click="optionTap(index)"
                                      v-for="(item, index) in option"
                                      :key="index">{{item.name}}
                                </span>
                  </div>
                </div>
              </div>
              <div class="tel">{{hidePhone(userInfo.mobile)}}</div>
            </div>

            <phone-code :phone="userInfo.mobile" v-model="mobileParams.oldValidateCode"></phone-code>

            <div class="tel_input">
              <div class="jia86">
                <div class="select-box">
                  <div class="select-current">
                    <span class="current-name">{{option[index].name}}</span>
                  </div>
                  <div class="option-list" v-if="isShow">
                                <span class="option"
                                      @click="optionTap(index)"
                                      v-for="(item, index) in option"
                                      :key="index">{{item.name}}
                                </span>
                  </div>
                </div>
              </div>
              <div class="tel">
                <input type="number" class="input_code" v-model="mobileParams.newMobile" placeholder="请输入新手机号码">
              </div>
            </div>

            <phone-code :phone="mobileParams.newMobile" v-model="mobileParams.newValidateCode"></phone-code>
          </div>
        </template>
        <template v-else>
          <div class="form_item">
            <span class="label">手机号码</span>
            <span class="center_txt" v-if="edit[0]"></span>
            <span class="center_txt" v-else>未设置</span>
            <div class="btn" style="margin-right: 4px" v-if="edit[0]" @click="edit=[false,false,false,false]">取消</div>
            <div class="btn sure" @click="edit=[true,false,false,false]">{{edit[0] ? '确认绑定' : '绑定'}}</div>
          </div>
          <div class="edit_item" v-if="edit[0]">
            <div class="tel_input">
              <div class="jia86">
                <div class="select-box">
                  <div class="select-current">
                    <span class="current-name">{{option[index].name}}</span>
                  </div>
                  <div class="option-list" v-if="isShow">
                                <span class="option"
                                      @click="optionTap(index)"
                                      v-for="(item, index) in option"
                                      :key="index">{{item.name}}
                                </span>
                  </div>
                </div>
              </div>
              <div class="tel">
                <input type="number" class="input_code" v-model="bindMobile.mobile" placeholder="请输入手机号码">
              </div>
            </div>

            <phone-code :phone="bindMobile.mobile" v-model="bindMobile.code"></phone-code>
          </div>
        </template>

        <!--密码-->
<!--        <div class="form_item">-->
<!--          <span class="label">登录密码</span>-->
<!--          <span class="center_txt" v-if="edit[1]"></span>-->
<!--          <span class="center_txt" v-else>{{userInfo.password? '已设置': '未设置'}}</span>-->
<!--          <div class="btn" style="margin-right: 4px" v-if="edit[1]" @click="edit=[false,false,false,false]">取消</div>-->
<!--          <div :class="['btn', (edit[1] || !userInfo.password) && 'sure']" @click="changePassword">-->
<!--            {{edit[1] ?-->
<!--            userInfo.password?'确认变更':'确认设置' : userInfo.password?'变更':'设置'}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="edit_item" v-if="edit[1]">-->
<!--          <div class="tel_input">-->
<!--            <div class="jia86">-->
<!--              <div class="select-box">-->
<!--                <div class="select-current">-->
<!--                  <span class="current-name">{{option[index].name}}</span>-->
<!--                </div>-->
<!--                <div class="option-list" v-if="isShow">-->
<!--                                <span class="option"-->
<!--                                      @click="optionTap(index)"-->
<!--                                      v-for="(item, index) in option"-->
<!--                                      :key="index">{{item.name}}-->
<!--                                </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="tel">{{hidePhone(userInfo.mobile)}}</div>-->
<!--          </div>-->
<!--          <phone-code :phone="userInfo.mobile" v-model="passwordParams.validateCode"></phone-code>-->

<!--          <div class="tel_input" style="margin-bottom: 0;">-->
<!--            <div class="yanz_code">-->
<!--              <input type="password" class="input_code" v-model="passwordParams.newPassword"-->
<!--                     placeholder="设置新密码（6-18位字母数字组合）">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="tel_input" style="margin-bottom: 0;">-->
<!--            <div class="yanz_code">-->
<!--              <input type="password" class="input_code" placeholder="再次输入新密码" v-model="passwordParams.confirmPassword">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!--微信账号-->
        <div class="form_item" v-if="!userInfo.wxWebOpenId && !userInfo.wxServiceOpenId">
          <span class="label">微信账号</span>
          <span class="center_txt">未绑定</span>
          <div class="btn sure" @click="$toast.text('请电脑登录www.001ppt.com')">绑定</div>
        </div>
        <div class="form_item" v-else>
          <span class="label">微信账号</span>
          <span class="center_txt">已绑定</span>
          <div class="btn" @click="$toast.text('请电脑登录www.001ppt.com')">变更</div>
        </div>

        <!--实名认证-->
        <div class="form_item">
          <span class="label">实名认证</span>
          <span class="center_txt" v-if="!edit[3] && userCertify.nowRealName && userCertify.nowRealName.length">{{userCertify.nowRealName}} (已实名)</span>
          <span class="center_txt" v-else-if="!edit[3]">未认证</span>
          <span class="center_txt" v-else></span>
          <div class="btn" style="margin-right: 4px" v-if="edit[3]" @click="edit=[false,false,false,false]">取消</div>
          <div :class="userCertify.nowRealName && userCertify.nowRealName.length? 'btn':'btn sure'" v-if="!edit[3]"
               @click="edit=[false,false,false,true]">
            {{userCertify.nowRealName && userCertify.nowRealName.length?'变更':'认证'}}
          </div>
          <div class="btn sure" v-else @click="saveUserCertify">{{userCertify.nowRealName &&
            userCertify.nowRealName.length?'确认变更':'提交'}}
          </div>
        </div>
        <div class="edit_item" v-if="edit[3]">
          <div class="tel_input" style="margin: 8px 0 0 0">
            <div class="yanz_code">
              <input type="text" placeholder="请输入真实姓名" v-model="userCertify.realName"/>
            </div>
          </div>
          <div class="tel_input" style="margin-bottom: 0">
            <div class="yanz_code">
              <input type="text" placeholder="请输入身份证号" v-model="userCertify.idCardNum"/>
            </div>
          </div>
          <div class="flex">
            <div class="id-card-input">
              <div class="id-card-box">
                <van-uploader :after-read="uploadIdCardFront">
                  <img v-if="!userCertify.idCardFront ||!userCertify.idCardFront.length"
                       src="https://cdn.001ppt.cn/h5/assets/svg/idcard_front.svg" alt="">
                  <img class="uploaded" v-else :src="userCertify.idCardFront" alt="">
                </van-uploader>
              </div>
              <div class="id-card-desc">请上传身份证正面</div>
            </div>
            <div class="id-card-input">
              <div class="id-card-box">
                <van-uploader :after-read="uploadIdCardBack">
                  <img v-if="!userCertify.idCardBack ||!userCertify.idCardBack.length"
                       src="https://cdn.001ppt.cn/h5/assets/svg/idcard_back.svg" alt="">
                  <img class="uploaded" v-else :src="userCertify.idCardBack" alt="">
                </van-uploader>
              </div>
              <div class="id-card-desc">请上传身份证背面</div>
            </div>
          </div>
          <div class="agree-deal">
            <van-checkbox shape="square" checked-color="#151d36" v-model="userCertify.agree" icon-size="14">
              <span style="font-size: 12px">我已同意</span>
            </van-checkbox>
            <span class="private_policy" @click="$router.push({path:'/about/agreement'})">《灵感严选隐私政策》</span>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
  import {mapGetters, mapMutations} from 'vuex'
  import {user} from '@/api'
  import avatar from '@/components/avatar'
  import phoneCode from '@/components/phoneCode'
  import {computeObjectFullPath, multipartPutObject} from "../../api/oss";
  import {v2UserCertifyApi} from "../../api/v2/V2UserCertifyApi";
  import GoPcDialog from "../../components/goPcDialog";

  export default {
    computed: {...mapGetters(['userInfo'])},
    components: {GoPcDialog, avatar, phoneCode},
    data() {
      return {
        option: [
          {id: 1, name: '+86'},
        ],
        isShow: false,
        index: 0,
        edit: [false, false, false, false],
        bindMobile: {
          mobile: '',
          code: '',
        },
        mobileParams: {
          newMobile: '',
          newValidateCode: '',
          oldMobile: '',
          oldValidateCode: ''
        },

        passwordParams: {
          confirmPassword: '',
          mobile: '',
          newPassword: '',
          validateCode: ''
        },

        userCertify: {
          nowRealName: '',   // 当前的真实姓名（渲染用）
          idCardFront: '',   // 身份证正面
          idCardBack: '',    // 身份证背面
          realName: '',      // 真实姓名
          idCardNum: '',     // 身份证号
          agree: false       // 是否同意协议
        }

      }
    },
    created() {
      this.mobileParams.oldMobile = this.userInfo.mobile;
      this.passwordParams.mobile = this.userInfo.mobile;
      this.loadUserCertify();

      const {tab} = this.$route.query;
      if (tab && tab === 'CERTIFY') {
        this.edit = [false, false, false, true];
      }
    },
    methods: {
      ...mapMutations(['SET_USERINFO']),

      optionTap(index) {
        this.index = index;
        this.isShow = false;
      },
      openClose() {
        this.isShow = !this.isShow;
      },
      setEditIndex(index) {
        switch (index) {
          case 0:
            if (!this.userInfo.mobile) {
              this.bindMobileFn();
            } else {
              this.changeMobile();
            }
            break;
          case 1:
            if (!this.userInfo.mobile) {
              this.$toast.info('请先绑定手机');
              return;
            } else {
              this.changePassword();
            }
            break;
        }
        this.$set(this.edit, index, true);
      },

      //绑定手机号
      async bindMobileFn() {
        if (!this.edit[0]) return;
        if (!this.bindMobile.mobile) return this.$toast.info('请输入新手机号');
        if (!this.bindMobile.code) return this.$toast.info('请输入验证码');
        const {code, data, msg} = await user.bindMobile(this.bindMobile);
        if (code != 1) return this.$toast.info(msg);
        this.userInfo.mobile = this.mobileParams.mobile;
        this.SET_USERINFO(this.userInfo);
        this.$toast.success('绑定手机号成功');
        this.mobileParams = {
          mobile: '',
          code: ''
        };
        this.$set(this.edit, 0, false);

      },

      //修改绑定手机号
      async changeMobile() {
        if (!this.edit[0]) return;
        if (!this.mobileParams.oldMobile) return this.$toast.info('请输入验证码');
        if (!this.mobileParams.newMobile) return this.$toast.info('请输入新手机号');
        if (!this.mobileParams.newValidateCode) return this.$toast.info('请输入验证码');
        const {code, msg} = await user.changeMobile(this.mobileParams);
        if (code != 1) return this.$toast.info(msg);
        this.userInfo.mobile = this.mobileParams.newMobile;
        this.SET_USERINFO(this.userInfo);
        this.$toast.success('修改手机号成功');
        this.mobileParams.oldValidateCode = '';
        this.mobileParams.newMobile = '';
        this.mobileParams.newValidateCode = '';
        this.$set(this.edit, 0, false);

      },


      //修改密码
      async changePassword() {
        if (!this.edit[1]) {
          return this.edit = [false, true, false, false];
        }
        if (!this.passwordParams.validateCode) return this.$toast.info('请输入验证码');
        if (!this.passwordParams.newPassword) return this.$toast.info('请输入密码');
        if (this.passwordParams.newPassword !== this.passwordParams.confirmPassword) return this.$toast.info('两次输入密码不一致');
        const {code, msg} = await user.changePassword(this.passwordParams);
        if (code != 1) return this.$toast.fail(msg);
        this.$toast.success('修改密码成功');
        this.userInfo.password = '******';
        this.SET_USERINFO(this.userInfo);
        this.passwordParams.confirmPassword = '';
        this.passwordParams.newPassword = '';
        this.passwordParams.validateCode = '';
        this.$set(this.edit, 1, false);
      },


      // 上传身份证正面
      async uploadIdCardFront({file}) {
        const {id} = this.userInfo;
        const timestamp = Date.now();
        const objectName = `userCertify/${id}/idCardFront-${timestamp}.png`;
        await multipartPutObject(file, objectName);
        // console.log(computeObjectFullPath(objectName));
        this.userCertify.idCardFront = computeObjectFullPath(objectName);
        this.$forceUpdate();
      },

      // 上传身份证背面
      async uploadIdCardBack({file}) {
        const {id} = this.userInfo;
        const timestamp = Date.now();
        const objectName = `userCertify/${id}/idCardBack-${timestamp}.png`;
        await multipartPutObject(file, objectName);
        this.userCertify.idCardBack = computeObjectFullPath(objectName);
        this.$forceUpdate();
      },


      // 加载用户实名认证数据
      async loadUserCertify() {
        const res = await v2UserCertifyApi.findMy();
        if (res) {
          res.agree = true;
          res.nowRealName = res.realName;
        }
        this.userCertify = res || {};
      },

      // 提交用户认证数据
      async saveUserCertify() {
        const {realName, idCardNum, idCardBack, idCardFront, agree} = this.userCertify;
        if (!realName || !realName.length) {
          return this.$toast.info('请输入真实姓名');
        }
        if (!idCardNum || !idCardNum.length) {
          return this.$toast.info('请输入身份证号');
        }
        if (idCardNum.length !== 15 && idCardNum.length !== 18) {
          return this.$toast.info('身份证长度有误');
        }
        if (!idCardFront || !idCardFront.length) {
          return this.$toast.info('请上传身份证正面照片');
        }
        if (!idCardBack || !idCardBack.length) {
          return this.$toast.info('请上传身份证背面照片');
        }
        if (!agree) {
          return this.$toast.info('请勾选同意协议');
        }

        const form = JSON.parse(JSON.stringify(this.userCertify));
        await v2UserCertifyApi.saveMy(form);
        this.$toast.success('提交用户认证数据成功');
        this.userCertify.nowRealName = realName;
        const {edit} = this;
        edit[3] = false;
        this.edit = [...edit];
      }

    }
  }
</script>

<style lang="less" scoped>
  .infoMsg {
    background-color: #fff;
  }

  .infoMsg .header {
    padding: 20px;
    display: flex;
    justify-content: center;

  }

  .infoMsg .header .box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .avatar_box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }

  .box .avatar {
    width: 100%;
    height: 100%;
  }

  .user_name {
    margin-top: 10px;
    line-height: 150%;
    font-size: 14px;
    font-weight: bold;
  }

  .gray {
    background-color: #f7f7f7;
    height: 15px;
  }

  .form {
    padding: 28px;
    display: flex;
    flex-direction: column;
  }

  .form_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  .form_item .label {
    font-size: 14px;
    font-weight: bold;
  }

  .form_item .center_txt {
    font-size: 14px;
    color: #64151d36;
    padding-left: 12px;
    flex: 1;

    &.sure {
      background-color: #151d36;
      color: #fff;
    }
  }

  .form_item .btn {
    width: 80px;
    font-size: 12px;
    text-align: center;
    padding: 5px 0;
    border-radius: 13px;
    border: 1px solid #151d36;
  }

  .form_item .btn.sure {
    background-color: #151d36;
    color: #fff;
  }

  .edit_item {
    display: flex;
    flex-direction: column;
    width: 272px;
  }


  .edit_item .tel_input {
    display: flex;
    margin-bottom: 15px;
  }

  .edit_item .tel_input .jia86 {
    background-color: #f3f3f5;
    padding: 7px 0;
    border-radius: 4px;
  }

  .edit_item .id-card-input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 14px;

    .id-card-desc {
      margin-top: 8px;
      color: #151d36;
      opacity: 0.3;
    }
  }

  .edit_item .id-card-box {
    height: 76px;
    width: 120px;
    background: #f3f3f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 88px;
      height: 60px;

      &.uploaded {
        width: 120px !important;
        height: 76px !important;
      }
    }
  }

  .phcolor {
    color: #D3D3D3;
  }

  .edit_item .tel_input .tel {
    flex: 1;
    margin-left: 10px;
    background-color: #f3f3f5;
    border-radius: 4px;
    font-size: 14px;
    padding: 7px;
    color: #cdcfd5;
  }

  .edit_item .yanz_code {
    background-color: #f3f3f5;
    border-radius: 4px;
    padding: 7px;
    color: #cdcfd5;
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 15px;

    input {
      border: none;
      outline: none;
      background-color: #f3f3f5;
    }
  }

  .edit_item .input_code {
    flex: 1;
    width: 100%;
    font-size: 14px;
    color: #151d36;
    border: none;
    outline: none;
    background-color: #f3f3f5;
  }

  .edit_item .yanz_code .tap_get_code {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-left: 1px solid #999999;
  }

  /* 下拉 */
  .select-box {
    position: relative;
    width: 100%;
  }

  .select-current {
    width: 65px;
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
  }

  .select-current::after {
    position: absolute;
    display: block;
    right: 8px;
    top: 8px;
    content: '';
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top: 5px solid #D3D3D3;
  }

  .current-name {
    display: block;
    width: 85%;
    height: 100%;
    word-wrap: normal;
    overflow: hidden;
    color: #D3D3D3;
  }

  .option-list {
    position: absolute;
    left: 0;
    top: 27px;
    width: 100%;
    padding: 2px 5px 2px 5px;
    border-radius: 3px;
    box-sizing: border-box;
    z-index: 99;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) inset;
    background-color: #fff;
  }

  .option {
    font-size: 14px;
    display: block;
    width: 100%;
    line-height: 35px;
    border-bottom: 1px solid #eee;
  }

  .option:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .agree-deal {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  .private_policy {
    font-size: 12px;
    color: rgba(14, 143, 251, 1);
  }
</style>
