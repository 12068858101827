import {v2Request} from "./v2Request";


export const v2UserCertifyApi = {

  // 查找我的用户认证信息
  async findMy() {
    return v2Request.get(`userCertify/findMy`);
  },

  // 保存
  async saveMy(userCertify) {
    return v2Request.post(`userCertify/saveMy`, userCertify);
  }

};
